import React from 'react'
import { ToastProvider } from '../../hooks/useToast'

const ContentProvider = ({ children }) => {
  return (
    <ToastProvider>
      { children }
    </ToastProvider>
   )
}

export default ContentProvider